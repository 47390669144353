<template>
  <div class="shopInfo">
    <div class="title-l1">商家人员构成</div>
    <div class="title-l2">至少填写2个人信息，包含主播、直播间运营</div>
    <el-form :model="liveInfo" ref="liveInfo" label-width="140px">
      <template v-for="(item,index) in liveInfo.live_person_infos">
        <el-form-item label="姓名" :prop="'live_person_infos.' + index + '.person_name'"
          :rules="[{required: true, message: '请输入人员姓名', trigger: 'blur'}]">
          <el-input v-model="item.person_name" placeholder="请输入人员姓名" maxlength="50">
          </el-input>
        </el-form-item>
        <el-form-item label="职位" :prop="'live_person_infos.' + index + '.person_posts'"
          :rules="[{required: true, message: '请输入人员职位', trigger: 'blur'}]">
          <el-input v-model="item.person_posts" placeholder="请输入人员职位" maxlength="20">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" :prop="'live_person_infos.' + index + '.person_mobile'"
          :rules="[{required: true,message: '请输入手机号',trigger: 'change'},{pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,message: '请输入正确的手机号码',trigger: 'change'}]">
          <el-input v-model="item.person_mobile" placeholder="请输入手机号" maxlength="11">
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号" :prop="'live_person_infos.' + index + '.person_card_number'" :rules="[{
            required: true,
            message: '请输入身份证号',
            trigger: 'change'
          }, {
            pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请输入正确的身份证号',
            trigger: 'change'
          }]">
          <el-input v-model="item.person_card_number" placeholder="请输入身份证号" maxlength="20">
          </el-input>
        </el-form-item>
        <el-form-item label="身份证照片" :prop="'live_person_infos.' + index + '.card_front_url'" :rules="card_url">
          <div class="certificate-img">
            <div class="upload-img">
              <el-upload :class="{ disabled: item.uploadDisabled }" :on-change="handleChange.bind(null,index)" action=""
                list-type="picture-card" :on-preview="handlePictureCardPreview" :limit="limitOne"
                :on-remove="handleRemove.bind(null,index)" :http-request='getFileOne.bind(null,index)'
                :file-list="item.fileListOne" accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
                <div class="upload-hint">
                  <img src="../../../assets/images/shangchuantupian备份 2@2x.png" />
                  <span>上传人像面</span>
                </div>
              </el-upload>
            </div>
            <div class="upload-img">
              <el-upload :class="{ disabled: item.uploadDisabledTwo }" :on-change="handleChangeTwo.bind(null,index)"
                action="" list-type="picture-card" :on-preview="handlePictureCardPreview" :limit="limitOne"
                :on-remove="handleRemoveTwo.bind(null,index)" :http-request='getFileTwo.bind(null,index)'
                :file-list="item.fileListTwo" accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
                <div class="upload-hint">
                  <img src="../../../assets/images/shangchuantupian备份 2@2x.png" />
                  <span>上传国徽面</span>
                </div>
              </el-upload>
            </div>
          </div>
        </el-form-item>
      </template>
      <div class="add-btn">
        <span @click="addItem()">添加成员</span>
        <span @click="delItem()">删除成员</span>
      </div>
    </el-form>
    <div class="button-okz">
      <button class="go-one" @click="next('liveInfo')">提交</button>
      <button class="go-two" @click="nextBack()">返回上一步</button>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  import {
    uploadFile
  } from '../../../api/index'
  import OSS from 'ali-oss'
  export default {
    name: 'shopInfo',
    data() {
      return {
        liveInfo: {
          live_person_infos: [{
            person_name: '', // 人员姓名
            person_posts: '', // 人员职位
            person_mobile: '', // 人员手机号
            person_card_number: '', // 人员身份证
            card_front_url: '', // 身份证正面图
            card_back_url: '', // 身份证反面图
            uploadDisabled: false, //隐藏上传图标
            uploadDisabledTwo: false,
            fileListOne: [], //上传图片list
            fileListTwo: []
          }, {
            person_name: '', // 人员姓名
            person_posts: '', // 人员职位
            person_mobile: '', // 人员手机号
            person_card_number: '', // 人员身份证
            card_front_url: '', // 身份证正面图
            card_back_url: '', // 身份证反面图
            uploadDisabled: false, //隐藏上传图标
            uploadDisabledTwo: false,
            fileListOne: [], //上传图片list
            fileListTwo: []
          }]
        },
        limitOne: 1,
        dialogImageUrl: '',
        dialogVisible: false,
        ossToken: null,
        card_url: [{
          required: true,
          trigger: ['blur', 'change'],
          validator: (rule, value, callback) => {
            const index = Number(rule.field.split('.')[1]);
            if (this.liveInfo.live_person_infos[index].card_front_url === '') {
              callback(new Error('请上传身份证照片人像面'))
            } else if (this.liveInfo.live_person_infos[index].card_back_url === '') {
              callback(new Error('请上传身份证照片国徽面'))
            } else {
              callback()
            }
          },
        }],
      }
    },
    props: {
      shopInfoData: {
        type: Array,
        default: []
      }
    },
    created() {},
    methods: {
      echoData() {
        this.liveInfo.live_person_infos = this.shopInfoData
        this.liveInfo.live_person_infos.forEach(item => {
          item.fileListOne = [{
            url: item.card_front_url
          }]
          item.fileListTwo = [{
            url: item.card_back_url
          }]
          item.uploadDisabled = true //隐藏上传图标
          item.uploadDisabledTwo = true
        })
      },
      addItem() {
        this.liveInfo.live_person_infos.push({
          person_name: '', // 人员姓名
          person_posts: '', // 人员职位
          person_mobile: '', // 人员手机号
          person_card_number: '', // 人员身份证
          card_front_url: '', // 身份证正面图
          card_back_url: '', // 身份证反面图
          uploadDisabled: false, //隐藏上传图标
          uploadDisabledTwo: false,
          fileListOne: [], //上传图片list
          fileListTwo: []
        })
      },
      delItem() {
        if (this.liveInfo.live_person_infos.length > 2) {
          this.liveInfo.live_person_infos.pop()
        }
      },
      nextBack() {
        this.$emit('back')
      },
      next(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let obj = []
            this.liveInfo.live_person_infos.forEach(item => {
              obj.push({
                person_name: item.person_name, // 人员姓名
                person_posts: item.person_posts, // 人员职位
                person_mobile: item.person_mobile, // 人员手机号
                person_card_number: item.person_card_number, // 人员身份证
                card_front_url: item.card_front_url, // 身份证正面图
                card_back_url: item.card_back_url // 身份证反面图
              })
            })
            this.$emit("submit", obj)
          } else {
            return false
          }
        })
      },
      handleChange(index, file, fileList) {
        if (fileList.length >= 1) {
          this.liveInfo.live_person_infos[index].uploadDisabled = true
        }
      },
      handleChangeTwo(index, file, fileList) {
        if (fileList.length >= 1) {
          this.liveInfo.live_person_infos[index].uploadDisabledTwo = true;
        }
      },
      handleRemove(index, file, fileList) {
        this.liveInfo.live_person_infos[index].uploadDisabled = false;
        this.liveInfo.live_person_infos[index].card_front_url = ''
        this.$refs.liveInfo.validateField('live_person_infos.' + index + '.card_front_url')
      },
      handleRemoveTwo(index, file, fileList) {
        this.liveInfo.live_person_infos[index].uploadDisabledTwo = false;
        this.liveInfo.live_person_infos[index].card_back_url = ''
        this.$refs.liveInfo.validateField('live_person_infos.' + index + '.card_front_url')
      },
      handlePictureCardPreview(file) { //放大
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      // 上传身份证人像
      async getFileOne(index, event) {
        this.liveInfo.live_person_infos[index].card_front_url = await this.getUpImg(event.file)
        this.$refs.liveInfo.validateField('live_person_infos.' + index + '.card_front_url')
      },
      // 上传身份证国徽
      async getFileTwo(index, event) {
        this.liveInfo.live_person_infos[index].card_back_url = await this.getUpImg(event.file)
        this.$refs.liveInfo.validateField('live_person_infos.' + index + '.card_front_url')
      },
      getUpImg(file) { //获取上传图片路径
        return new Promise(async (resolve, reject) => {
          const uploadHost = 'https://pic.easyebid.com'
          if (!this.ossToken) await this.uploadFile()
          var client = new OSS({
            region: 'oss-cn-beijing',
            accessKeyId: this.ossToken.accessKeyId,
            accessKeySecret: this.ossToken.accessKeySecret,
            bucket: 'yideonline',
            stsToken: this.ossToken.securityToken
          })
          const type = file.name.split('.')
          const len = type.length
          const fileName = this.uuid() + '.' + type[len - 1]
          const result = await client.put(`cer/${fileName}`, file)
          if (result.res.statusCode === 200) {
            const successImg = `${uploadHost}/${result.name}`
            resolve(successImg)
          } else {
            this.$message.error('上传失败')
            reject()
          }
        })
      },
      //获取唯一标识
      uuid() {
        let s = [];
        const hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
      }
    }

  }
</script>

<style lang="less" scoped>
  .shopInfo {
    width: 800px;
    margin: 0 auto;

    /deep/.el-upload-list__item {
      transition: none;
    }

    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
      height: 50px;
      line-height: 50px;
    }

    /deep/ .el-input__inner {
      height: 50px;
      border: 1px solid #bfc3c4;
      background: #ffffff;
      color: #333;
      font-size: 16px;
    }

    /deep/ .el-upload--picture-card {
      width: 120px;
      height: 80px;
      background: #fff;
      border: 1px solid #bfc3c4;
      line-height: 80px;
      border-radius: 0;
    }

    .title-l1 {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      text-align: left;
      padding-left: 80px;
    }

    .title-l2 {
      font-size: 12px;
      font-weight: 400;
      color: #CD5656;
      text-align: left;
      padding-left: 80px;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .upload-img {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .upload-img-title {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        margin-left: 20px;
      }
    }

    .certificate-img {
      display: flex;
    }

    .sizeHint {
      color: #999;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .upload-hint {
      width: 120px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        color: #999999;
        font-size: 12px;
        height: 30px;
        line-height: 30px;

      }
    }

    .disabled {
      /deep/.el-upload--picture-card {
        display: none !important;
      }
    }

    .add-btn {
      display: flex;
      padding-left: 140px;
      margin-top: 50px;

      >span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 20px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #CDA156;
        font-size: 14px;
        font-weight: 400;
        color: #CDA156;
        cursor: pointer;
        flex-shrink: 0;

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;

      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        border-radius: 8px;
        height: 44px;
        line-height: 44px;
        border: none;
        margin-top: 40px;
        background: rgba(205, 161, 86, .7);
        cursor: pointer;
      }

      .go-two {
        width: 280px;
        color: #cda156;
        font-size: 18px;
        border: none;
        margin-top: 20px;
        background: rgba(255, 255, 255, .7);
        cursor: pointer;
      }
    }
  }
</style>
