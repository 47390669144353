<template>
  <div class="liveFor">
    <div class="step-auction">
      <el-steps :active="active">
        <el-step title="基本信息"></el-step>
        <el-step title="直播间信息"></el-step>
        <el-step title="提交成功"></el-step>
      </el-steps>
    </div>
    <basicInfo ref="basicInfo" v-show="active==1" @next="next" :basicInfoData="basicInfoData"></basicInfo>
    <shopInfo ref="shopInfo" v-show="active==2" @back="back" :shopInfoData="shopInfoData" @submit="applyEntryLive">
    </shopInfo>
    <success v-show="active==3"></success>
  </div>
</template>

<script>
  import {
    applyEntryLive,
    getMallApplyLiveInfo,
    getMallApplyShopInfo
  } from '../../api/index'
  import basicInfo from './components/basicInfo'
  import shopInfo from './components/shopInfo'
  import success from './components/success'
  export default {
    name: 'liveFor',
    components: {
      basicInfo,
      shopInfo,
      success
    },
    data() {
      return {
        active: 1,
        basicInfoData: {},
        shopInfoData: [],
        basicInfoDataOne: {}
      }
    },
    created() {
      if (this.$route.query.edit == 1) {
        this.getMallApplyLiveInfo()
      }
    },
    methods: {
      back(e) {
        this.active = 1
      },
      next(e) {
        this.active = 2
        this.basicInfoData = e //拿到基础信息data
      },
      async applyEntryLive(e) {
        let submitData = {}
        this.shopInfoData = e //店铺信息data
        //合并数据
        submitData = {
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id')),
          ...this.basicInfoData,
          live_person_infos: this.shopInfoData
        }
        const res = await applyEntryLive(submitData)
        if (res.flag === true) {
          this.active = 3
        } else {
          this.$message.error(res.msg)
        }
      },
      async getMallApplyLiveInfo() {
        const res = await getMallApplyLiveInfo({
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id'))
        })
        if (res.flag === true) {
          let live_category_id = res.data.live_sale_category_levels.split(',')
          live_category_id = live_category_id.map(item => {
            return item = Number(item)
          })
          this.basicInfoDataOne = {
            live_category_id: live_category_id,
            live_region: res.data.live_region,
            live_license: res.data.live_license,
            shop_month_sales: res.data.shop_month_sales,
            shop_order_count: res.data.shop_order_count
          }
          this.shopInfoData = res.data.live_person_infos
          this.$nextTick(() => {
            this.$refs.shopInfo.echoData()
          })
          this.getMallApplyShopInfo() //店铺名称和经营品类单独拿
        } else {
          this.$message.error(res.msg)
        }
      },
      async getMallApplyShopInfo() {
        const res = await getMallApplyShopInfo({
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id'))
        })
        if (res.flag === true) {
          let companies_range = res.data.companies_range_levels.split(',')
          companies_range = companies_range.map(item => {
            return item = Number(item)
          })
          const basicInfoDataTwo = {
            shop_name: res.data.shop_name,
            shop_sale_category: companies_range
          }
          this.basicInfoData = {
            ...this.basicInfoDataOne,
            ...basicInfoDataTwo
          }
          this.$nextTick(() => {
            this.$refs.basicInfo.echoData()
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    }

  }
</script>

<style lang="less" scoped>
  .liveFor {
    .step-auction {
      padding: 0 650px;
      margin: 20px 0;

      /deep/.el-step__head.is-process {
        color: #C0C4CC;
        border-color: #C0C4CC;
      }

      /deep/.el-step__title.is-process {
        color: #C0C4CC;
      }

      /deep/ .el-step.is-horizontal {
        flex-basis: 0% !important;
      }

      /deep/ .el-step__line-inner {
        color: #cda156;
      }

      /deep/ .el-step__head.is-finish {
        color: #cda156;
        border-color: #cda156;
      }

      /deep/ .el-step__icon.is-text {
        margin-right: 274px !important;
      }

      /deep/ .el-step__title.is-finish {
        color: #cda156;
      }

      /deep/ .el-step__main {
        width: 70px;
        margin-left: -18px;
      }
    }
  }
</style>
