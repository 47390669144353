<template>
  <div class="basicInfo">
    <el-form :model="liveInfo" :rules="rules" ref="liveInfo" label-width="140px">
      <el-form-item label="经营品类" prop="live_category_id">
        <el-cascader v-if="isShowCascader" style="width: 100%;" :props="propsOne" v-model="liveInfo.live_category_id">
        </el-cascader>
      </el-form-item>
      <el-form-item label="直播地区" prop="live_region">
        <el-input v-model="liveInfo.live_region" placeholder="请输入直播地区" maxlength="50">
        </el-input>
      </el-form-item>
      <el-form-item label="已获得的经营许可证及经营范围" prop="live_license">
        <el-input type="textarea" v-model="liveInfo.live_license" placeholder="请输入已获得的经营许可证及经营范围" maxlength="1000">
        </el-input>
      </el-form-item>
      <div class="center-title">已在焱十一开通的店铺信息</div>
      <el-form-item label="店铺名称">
        <el-input :disabled="true" v-model="liveInfo.shop_name" placeholder="请输入店铺名称" maxlength="30">
        </el-input>
      </el-form-item>
      <el-form-item label="经营品类">
        <el-cascader :disabled="true" v-if="isShowCascader" style="width: 100%;" :props="propsOne"
          v-model="liveInfo.shop_sale_category">
        </el-cascader>
      </el-form-item>
      <el-form-item label="月销售额">
        <el-input v-model="liveInfo.shop_month_sales" placeholder="请输入月销售额" maxlength="30">
        </el-input>
      </el-form-item>
      <el-form-item label="月订单数">
        <el-input v-model="liveInfo.shop_order_count" placeholder="请输入月订单数" maxlength="30">
        </el-input>
      </el-form-item>
    </el-form>
    <div class="button-okz">
      <button class="go-one" @click="next('liveInfo')">下一步</button>
    </div>
  </div>
</template>

<script>
  import {
    getSaleCategory,
    getMallApplyShopInfo
  } from '../../../api/index'
  export default {
    name: 'basicInfo',
    data() {
      return {
        liveInfo: {
          live_category_id: [],
          live_region: '',
          live_license: '',
          shop_name: '',
          shop_sale_category: [],
          shop_month_sales: '',
          shop_order_count: ''
        },
        rules: {
          live_category_id: [{
            required: true,
            message: '请选择经营品类',
            trigger: 'change'
          }],
          live_region: [{
            required: true,
            message: '请输入直播地区',
            trigger: 'change'
          }],
          live_license: [{
            required: true,
            message: '请输入已获得的经营许可及经营范围',
            trigger: 'change'
          }]
        },
        propsOne: {
          lazy: true,
          async lazyLoad(node, resolve) {
            const {
              level
            } = node;
            const nodes = [];
            let code = level == 0 ? "0" : node.value;
            const res = await getSaleCategory({
              sale_category_id: code
            })
            if (res.flag === true) {
              res.categoryList.map(item => {
                nodes.push({
                  value: item.id,
                  label: item.categoryName,
                  leaf: level >= 1
                })
              })
              resolve(nodes);
            } else {
              this.$message.error(res.msg)
            }
          }
        },
        isShowCascader: true
      }
    },
    //属性
    props: {
      basicInfoData: {
        type: Object,
        default: {}
      }
    },
    created() {
      if (this.$route.query.edit == 1) return
      this.getMallApplyShopInfo()
    },
    methods: {
      next(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let obj = JSON.parse(JSON.stringify(this.liveInfo))
            obj.live_category_id = Number(obj.live_category_id[obj.live_category_id.length - 1])
            if (obj.shop_sale_category.length > 0) {
              obj.shop_sale_category = Number(obj.shop_sale_category[obj.shop_sale_category.length - 1])
            } else {
              obj.shop_sale_category = 0
            }
            this.$emit("next", obj)
          } else {
            return false
          }
        })
      },
      echoData() { //回显数据
        this.liveInfo = this.basicInfoData
        this.isShowCascader = false
        setTimeout(() => {
          this.isShowCascader = true
        }, 100)
      },
      async getMallApplyShopInfo() {
        const res = await getMallApplyShopInfo({
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id'))
        })
        if (res.flag === true) {
          let companies_range = res.data.companies_range_levels.split(',')
          companies_range = companies_range.map(item => {
            return item = Number(item)
          })

          this.liveInfo.shop_name = res.data.shop_name
          this.liveInfo.shop_sale_category = companies_range
          this.isShowCascader = false
          setTimeout(() => {
            this.isShowCascader = true
          }, 100)

        } else {
          this.$message.error(res.msg)
        }
      }
    }

  }
</script>

<style lang="less" scoped>
  .basicInfo {
    width: 800px;
    margin: 0 auto;

    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
      height: 50px;
    }

    /deep/ .el-input__inner {
      height: 50px;
      border: 1px solid #bfc3c4;
      background: #ffffff;
      color: #333;
      font-size: 16px;
    }

    .center-title {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      text-align: left;
      margin: 30px 0;
    }

    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;

      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        border-radius: 8px;
        height: 44px;
        line-height: 44px;
        border: none;
        margin-top: 40px;
        background: rgba(205, 161, 86, .7);
        cursor: pointer;
      }
    }
  }
</style>
